.pagination {
  font-family: $font-stack;
  margin: 2rem 0;
  color: #ccc;

  > * {
    padding: 0.25rem 0.5rem;
  }

  a {
    &:focus,
    &:hover {
      text-decoration: none;
      background-color: #f5f5f5;
    }
  }

  > .older {
    float: left;
    text-align: left;
  }

  > .newer {
    float: right;
    text-align: right;
  }

  &::after {
    content: "";
    display: table;
    clear: both;
  }
}
