.container {
  max-width: 48rem;
  padding-left:  1.5rem;
  padding-right: 1.5rem;
  margin-left:  auto;
  margin-right: auto;
}

.ftr-links a {
  color: $post-title-color;
  padding: 0 0.3rem;
}
