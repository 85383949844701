* {
  @include prefix(box-sizing, border-box);
}

html {
  font-family: $font-stack;
  font-size: $font-size;
  line-height: 1.5;

  @media (max-width: 38em) {
    font-size: $font-size-mobile;
  }
}

body {
  color: $body-text-color;
  background-color: $body-background-color;
}

a {
  color: $link-color;
  text-decoration: none;

  &:hover,
  &:focus {
    text-decoration: underline;
  }

  strong {
    color: inherit;
  }
}

footer {
    text-align: center;
    margin-bottom: 2rem;
}

img {
  display: block;
  max-width: 100%;
  margin: 0 0 1rem;
  border-radius: 5px;
}

table {
  margin-bottom: 1rem;
  width: 100%;
  font-size: 85%;
  border: 1px solid #e5e5e5;
  border-collapse: collapse;
}

td,
th {
  padding: .25rem .5rem;
  border: 1px solid #e5e5e5;
}

th {
  text-align: left;
}

tbody tr:nth-child(odd) td,
tbody tr:nth-child(odd) th {
  background-color: #f9f9f9;
}

video {
  width: 100%;
  height: auto;
}
