@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/montserrat-v25-latin-regular.woff2') format('woff2'),
       url('/fonts/montserrat-v25-latin-regular.woff') format('woff');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: url('/fonts/montserrat-v25-latin-700.woff2') format('woff2'),
       url('/fonts/montserrat-v25-latin-700.woff') format('woff');
}
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 400;
  src: url('/fonts/montserrat-v25-latin-italic.woff2') format('woff2'),
       url('/fonts/montserrat-v25-latin-italic.woff') format('woff');
}
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 700;
  src: url('/fonts/montserrat-v25-latin-700italic.woff2') format('woff2'),
       url('/fonts/montserrat-v25-latin-700italic.woff') format('woff');
}
