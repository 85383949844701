article.page,
article.post {
  margin-bottom: 4em;

  > header .subtitle {
    margin-top: -.4rem;
    margin-bottom: 1rem;

    time {
      color: $post-date-color;
      margin-right: 0.7rem;
    }

    .tags {
      display: inline-block;
      padding-left: 0;

      li {
        display: inline-block;

        &::after {
          display: inline;
          content: ", ";
          margin-right: .25rem;
        }

        &:last-child::after {
          display: inline;
          content: "";
          margin-right: 0;
        }
      }
    }
  }
}


// Blog post or page title
.page-title,
.post-title,
.post-title a {
  color: $post-title-color;
}
.page-title,
.post-title {
  margin-top: 0;
}

#disqus_loader {
  text-align: center;

  a.loaded {
    display: none;
  }
}
