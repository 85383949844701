.vimeo-embed {
  position: relative;
  padding: 62.5% 0 0 0;

  > iframe {
    position: absolute;
    border: none;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
}
