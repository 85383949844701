// Variables
@import "utilities/variables";

// Utility styles
@import "utilities/mixins";
@import "utilities/utils";
@import "utilities/syntax-highlighting";
@import "utilities/animations";

// Core
@import "base/fonts";
@import "base/fontawesome";
@import "base/normalize";
@import "base/global";
@import "base/layout";
@import "base/typography";

// Components
@import "components/navigation";
@import "components/pagination";
@import "components/code";
@import "components/message";
@import "components/vimeo";

// Pages
@import "pages/landing";
@import "pages/posts";
@import "pages/profile";
